import React from 'react'
import SEO from '../components/seo'
import Container from '../components/container'
import HeroRed from '../assets/hero-red.svg'

const NotFoundPage = () => (
    <>
        <SEO title='404: Not found'/>
        <div
            className={`bg-brand-red-lighter text-white flex items-end justify-center relative z-20 pb-40 min-h-screen`}>
            <img src={HeroRed} className='absolute mix-blend-mode z-20 top-0'/>
            <Container className='relative z-30'>
                <div className="px-24">
                    <div className='w-full' style={{maxWidth: '650px'}}>
                        <h2 className='font-heading font-semibold  leading-tight text-5xl tracking-tight mb-8'>Fant ikke
                            denne siden</h2>

                        <p className='text-base leading-normal'>Det ser ut til at det er litt rusk i maskineriet. Er du
                            sikker på at det er riktig adresse?</p>
                    </div>
                </div>
            </Container>
        </div>
    </>
)

export default NotFoundPage
